import { navigate } from 'gatsby';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { publishRecipe, unpublishRecipe } from '../actions/recipe';
import { getRecipes } from '../actions/recipes';
import { deploySite } from '../actions/site';
import RecipeListItem from '../components/RecipeListItem';
import { RecipeList } from '../types';

const Toolbar = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledButton = styled.button`
  background-color: whitesmoke;
  width: 150px;
  border-radius: 50px;
  &.margin-r {
    margin-left: auto;
    margin-right: 2rem;
  }
  &.small-text {
    font-size: 0.9rem;
  }
`;

const DeployButton = styled.button`
  background-color: whitesmoke;
  width: 150px;
  border-radius: 50px;
  border-color: ${(props) => (props.disabled ? '' : 'tomato')};
`;

const StyledInput = styled.input`
  margin-left: 15px;

`

const mapStateToProps = ({ recipes }) => ({ recipes });

const mapDispatchToProps = (dispatch) => ({
  getRecipesAction: (query: string) => dispatch(getRecipes(query)),
  publishRecipeAction: (recipeId: string) => dispatch(publishRecipe(recipeId)),
  unpublishRecipeAction: (recipeId: string) => dispatch(unpublishRecipe(recipeId)),
  deploySiteAction: () => dispatch(deploySite()),
});

interface Props {
  recipes: RecipeList;
  getRecipesAction: Function;
  publishRecipeAction: Function;
  unpublishRecipeAction: Function;
  deploySiteAction: Function;
}

const IndexPage: React.FC<Props> = ({
  recipes,
  getRecipesAction,
  publishRecipeAction,
  unpublishRecipeAction,
  deploySiteAction,
}) => {
  const [deployed, setDeployed] = useState(false);
  useEffect(() => {
    getRecipesAction();
  }, []);

  const createRecipeHandler = () => {
    navigate('/recipes/new');
  };

  const navigateIngredientsPageHandler = () => {
    navigate('/ingredients');
  };

  const navigateFeaturedPageHandler = () => {
    navigate('/featured');
  };

  const deployButtonHandler = () => {
    setDeployed(true);
    deploySiteAction();
  };

  const searchRecipe = (value: string) => {
      // TODO: debounce this 
      getRecipesAction(value);
  }

  return (
    <>
      <ToastContainer />
      <h2>Recipes</h2>
      <Toolbar>
        <StyledButton onClick={createRecipeHandler}>New Recipe</StyledButton>
        <StyledInput placeholder='Search' onChange={(e) => searchRecipe(e.target.value)} />
        <StyledButton className="small-text margin-r" onClick={navigateFeaturedPageHandler}>
          Featured Recipes
        </StyledButton>
        <StyledButton className="small-text" onClick={navigateIngredientsPageHandler}>
          View Ingredients
        </StyledButton>
      </Toolbar>
      {recipes?.map((recipe) => (
        <RecipeListItem
          key={recipe.id}
          recipe={recipe}
          publishRecipeHandler={publishRecipeAction}
          unpublishRecipeHandler={unpublishRecipeAction}
        />
      ))}
      <DeployButton onClick={deployButtonHandler} disabled={deployed}>
        Deploy Site
      </DeployButton>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(IndexPage);
