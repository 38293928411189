import { navigate } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

import { RecipeListRecipe, published, publish, unpublish } from '../types';

interface Props {
  recipe: RecipeListRecipe;
  publishRecipeHandler: Function;
  unpublishRecipeHandler: Function;
}

const RecipeListItemWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  background: whitesmoke;
  justify-content: space-between;
  align-items: center;

  margin: 10px 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 45%;
`;

const DataWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 50%;
`;

const RecipeListItemColumn = styled.div`
  padding: 1%;
  width: 100%;
  p {
    margin: auto;
  }
`;

const RecipeListButton = styled.button`
  border-radius: 15px;
  background-color: whitesmoke;
  width: 50%;
`;

const RecipeListItem: React.FC<Props> = ({ recipe, unpublishRecipeHandler, publishRecipeHandler }) => {
  const isPublished = recipe.recipeStatus.state === published;
  const buttonText = isPublished ? unpublish : publish;

  const editRecipeHandler = () => {
    navigate(`/recipes/${recipe.id}`);
  };

  const changeRecipeStateHandler = async () => {
    if (isPublished) {
      unpublishRecipeHandler(recipe.id);
    } else {
      publishRecipeHandler(recipe.id);
    }
  };

  return (
    <RecipeListItemWrapper>
      <DataWrapper>
        <RecipeListItemColumn>
          <p>{recipe.title}</p>
        </RecipeListItemColumn>
        <RecipeListItemColumn>
          <p>{recipe.author?.name}</p>
        </RecipeListItemColumn>
        <RecipeListItemColumn>
          <p>{recipe.recipeStatus.state}</p>
        </RecipeListItemColumn>
      </DataWrapper>
      <ButtonWrapper>
        <RecipeListItemColumn>
          <RecipeListButton onClick={changeRecipeStateHandler}>{buttonText}</RecipeListButton>
        </RecipeListItemColumn>
        <RecipeListItemColumn>
          <RecipeListButton onClick={editRecipeHandler}>Edit</RecipeListButton>
        </RecipeListItemColumn>
      </ButtonWrapper>
    </RecipeListItemWrapper>
  );
};

export default RecipeListItem;
