import axios from 'axios';
import { toast } from 'react-toastify';

const { GATSBY_VERCEL_DEPLOY_HOOK } = process.env;

export const deploySite = () => async (dispatch) => {
  try {
    const res = await axios.post(GATSBY_VERCEL_DEPLOY_HOOK);
    dispatch(deploySiteSuccess(res));
    toast.success(`Deploying Site`);
  } catch (err) {
    dispatch(deploySiteFailure(err.message));
    toast.error(err.message);
  }
};

const deploySiteSuccess = (res) => ({ type: 'GET_DEPLOY_SUCCESS', payload: res });
const deploySiteFailure = (err) => ({ type: 'GET_DEPLOY_ERROR', payload: err });
