export const GET_RECIPE_SUCCESS = 'GET_RECIPE_SUCCESS';
export const GET_RECIPE_ERROR = 'GET_RECIPE_ERROR';
export const GET_INGREDIENTS_SUCCESS = 'GET_INGREDIENTS_SUCCESS';
export const GET_INGREDIENTS_ERROR = 'GET_INGREDIENTS_ERROR';
export const GET_PARENT_INGREDIENTS_SUCCESS = 'GET_PARENT_INGREDIENTS_SUCCESS';
export const GET_PARENT_INGREDIENTS_ERROR = 'GET_PARENT_INGREDIENTS_ERROR';
export const GET_RECIPES_SUCCESS = 'GET_RECIPES_SUCCESS';
export const GET_RECIPES_ERROR = 'GET_RECIPES_ERROR';
export const GET_RECIPES_SEARCH_SUCCESS = 'GET_RECIPES_SEARCH_SUCCESS';
export const GET_RECIPES_SEARCH_FAILURE = 'GET_RECIPES_SEARCH_FAILURE';
export const GET_FEATURED_RECIPES_SUCCESS = 'GET_FEATURED_RECIPES_SUCCESS';
export const GET_FEATURED_RECIPES_ERROR = 'GET_FEATURED_RECIPES_ERROR';

export const CREATE_RECIPE_SUCCESS = 'CREATE_RECIPE_SUCCESS';
export const CREATE_RECIPE_ERROR = 'CREATE_RECIPE_ERROR';
export const UPDATE_RECIPE_SUCCESS = 'UPDATE_RECIPE_SUCCESS';
export const UPDATE_RECIPE_ERROR = 'UPDATE_RECIPE_ERROR';
export const CREATE_INGREDIENT_SUCCESS = 'CREATE_INGREDIENT_SUCCESS';
export const CREATE_INGREDIENT_ERROR = 'CREATE_INGREDIENT_ERROR';
export const CLEAR_INGREDIENTS = 'CLEAR_INGREDIENTS';
export const UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_ERROR = 'UPLOAD_IMAGE_ERROR';
export const CREATE_FEATURED_RECIPE_SUCCESS = 'CREATE_FEATURED_RECIPE_SUCCESS';
export const CREATE_FEATURED_RECIPE_ERROR = 'CREATE_FEATURED_RECIPE_ERROR';
export const UPDATE_FEATURED_RECIPE_STATUS_SUCCESS = 'UPDATE_FEATURED_RECIPE_STATUS_SUCCESS';
export const UPDATE_FEATURED_RECIPE_STATUS_ERROR = 'UPDATE_FEATURED_RECIPE_STATUS_ERROR';
export const UPDATE_ALL_FEATURED_RECIPES_SUCCESS = 'UPDATE_ALL_FEATURED_RECIPES_SUCCESS';
export const UPDATE_ALL_FEATURED_RECIPES_ERROR = 'UPDATE_ALL_FEATURED_RECIPES_ERROR';
export const CLEAR_RECIPE = 'CLEAR_RECIPE';
export const DELETE_RECIPE_SUCCESS = 'DELETE_RECIPE_SUCCESS';
export const DELETE_RECIPE_ERROR = 'DELETE_RECIPE_ERROR';
export const GET_AUTHORS_SUCCESS = 'GET_AUTHORS_SUCCESS';
export const GET_AUTHORS_ERROR = 'GET_AUTHORS_ERROR';
export const GET_CONSTRAINTS_SUCCESS = 'GET_CONSTRAINTS_SUCCESS';
export const GET_CONSTRAINTS_ERROR = 'GET_CONSTRAINTS_ERROR';
export const GET_DEPLOY_SUCCESS = 'GET_DEPLOY_SUCCESS';
export const GET_DEPLOY_ERROR = 'GET_DEPLOY_ERROR';

export type ACTION_TYPE =
  | typeof GET_RECIPE_SUCCESS
  | typeof GET_RECIPE_ERROR
  | typeof GET_RECIPES_SUCCESS
  | typeof GET_RECIPES_ERROR
  | typeof GET_RECIPES_SEARCH_SUCCESS
  | typeof GET_RECIPES_SEARCH_FAILURE
  | typeof GET_FEATURED_RECIPES_SUCCESS
  | typeof GET_FEATURED_RECIPES_ERROR
  | typeof CREATE_RECIPE_SUCCESS
  | typeof CREATE_RECIPE_ERROR
  | typeof UPDATE_RECIPE_SUCCESS
  | typeof UPDATE_RECIPE_ERROR
  | typeof GET_INGREDIENTS_SUCCESS
  | typeof GET_INGREDIENTS_ERROR
  | typeof GET_PARENT_INGREDIENTS_SUCCESS
  | typeof GET_PARENT_INGREDIENTS_ERROR
  | typeof CREATE_INGREDIENT_SUCCESS
  | typeof CREATE_INGREDIENT_ERROR
  | typeof CLEAR_INGREDIENTS
  | typeof UPLOAD_IMAGE_SUCCESS
  | typeof UPLOAD_IMAGE_ERROR
  | typeof CREATE_FEATURED_RECIPE_SUCCESS
  | typeof CREATE_FEATURED_RECIPE_ERROR
  | typeof UPDATE_FEATURED_RECIPE_STATUS_SUCCESS
  | typeof UPDATE_FEATURED_RECIPE_STATUS_ERROR
  | typeof UPDATE_ALL_FEATURED_RECIPES_SUCCESS
  | typeof UPDATE_ALL_FEATURED_RECIPES_ERROR
  | typeof CLEAR_RECIPE
  | typeof DELETE_RECIPE_SUCCESS
  | typeof DELETE_RECIPE_ERROR
  | typeof GET_AUTHORS_SUCCESS
  | typeof GET_AUTHORS_ERROR
  | typeof GET_CONSTRAINTS_SUCCESS
  | typeof GET_CONSTRAINTS_ERROR
  | typeof GET_DEPLOY_SUCCESS
  | typeof GET_DEPLOY_ERROR;

export interface ReduxAction<T> {
  type: ACTION_TYPE;
  payload?: T;
}

export type SimilarRecipe = Pick<Recipe, 'id' | 'cookTime' | 'title'> & {
  image: string;
};

export interface RecipeIngredient {
  id: number;
  unit: string;
  quantity: number;
  ingredient_data?: {
    title: string;
  };
  preparation?: string;
}

export const publish = 'publish';
export const unpublish = 'unpublish';

export const published = 'published';
export const unpublished = 'unpublished';
export type RecipeState = typeof published | typeof unpublished;

export interface RecipeStatus {
  id: number;
  state: RecipeState;
  url?: string;
}
export interface Recipe {
  id: string;
  preparation: string[];
  title: string;
  cookTime: string;
  description: string;
  author?: Author;
  cuisine: string;
  images: string[];
  ingredients: RecipeIngredient[];
  allowedConstraints?: string[];
  similarRecipes?: SimilarRecipe[];
  recipeStatus: RecipeStatus;
  servingSize: number;
}

export interface State {
  recipe?: Recipe;
}

export type RecipeListRecipe = Pick<Recipe, 'id' | 'title' | 'author'> & {
  recipeStatus: {
    state: string;
  };
};

export type RecipeList = RecipeListRecipe[];

export interface FeaturedRecipe {
  id: string;
  recipeID: string;
  imageURL: string;
  active: boolean;
  title: string;
  displayOrder: number;
}

export type FeaturedRecipeListRecipe = Pick<
  FeaturedRecipe,
  'id' | 'recipeID' | 'displayOrder' | 'imageURL' | 'title' | 'active'
>;

export type FeaturedRecipeList = FeaturedRecipeListRecipe[];

export interface Ingredient {
  id: number;
  title: string;
  tags: string[];
  ingredientBase: ParentIngredient;
  ingredientConstraints: IngredientConstraint[];
}

export type IngredientList = Ingredient[];

export interface IngredientConstraint {
  constraint_id: number;
  scale: number;
  constraints: {
    title: string;
  };
}

export interface ParentIngredient {
  title: string;
  id: number;
}

export type ParentIngredientList = ParentIngredient[];

export interface IngredientCreateParams {
  title: string;
  constraints: {
    title: string;
    variant: ConstraintVariant;
    scale: number;
  }[];
  parentId: number;
  tags: string[];
}

export interface IngredientModalValues {
  title: string;
  constraints: Array<{
    id: string;
    scale?: number;
  }>;
  parentId?: string;
  parentTitle?: string;
}

export interface UpdateIngredientInitialValues {
  id: string;
  ingredientBase: {
    title: string;
    id: string;
  };
  parentId: number;
  parentTitle: string;
  title: string;
  tags: string[];
  ingredient_constraints: {
    id: string;
    constraint_id: number;
    scale: number;
  }[];
}

export interface FeaturedRecipeModalValues {
  recipeID: string;
  active: boolean;
  files: FileList;
  title: string;
}

export interface Author {
  id: number;
  name: string;
  role: string;
  bio: string;
  instagram_url?: string;
  facebook_url?: string;
  twitter_url?: string;
  created_at?: string;
}

export type AuthorListAuthor = Pick<Author, 'id' | 'name'>;
export type AuthorList = AuthorListAuthor[];

export type ConstraintVariant = 'diet' | 'condition';
export interface Constraint {
  id: number;
  constraint_id: string;
  title: string;
  variant: ConstraintVariant;
}

export type ConstraintList = Constraint[];
